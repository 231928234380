/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Festival season was cancelled in 2020 but it’s back on for 2021 (fingers crossed). Festivals are crowded with people, so what’s this like for someone with social anxiety? I thought I’d share a little insight into how I feel about festivals and a couple of my bad experiences with you. "), "\n", React.createElement(_components.p, null, "In general I enjoy going to festivals. It’s a lot of money to spend if you’re going to hate the experience, so despite my fears and reservations, if the lineup is good they don’t stop me from going. I do however have an irrational fear that I’m going to fall over and be trampled on, that the tent will turn into a stampede to leave and I’ll be trampled to death, or that I’ll be randomly stabbed and no one will realise because everyone’s bopping away (do people still say bop?)."), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "‘I nearly passed out at a Justin Bieber concert’ is usually just something you’ll hear a crazed fan say (no shade - Beliebers don’t come for me). For me, Bieber was the last act of the night at V Fest and after a few songs my anxiety decided I needed to call it a night. I had a sudden urge for water – I’d drank all mine and none of my friends had any. Then I started to feel faint and luckily my best friend knew something was wrong without me telling her. She knew that I wouldn’t ask anyone to leave with me, nor would I leave by myself, so she offered to take me back to our tent. I felt SO bad but knew I didn’t really have an option. We’d only made our way a few steps before I stopped her as I felt an overwhelming feeling and knew I was going to pass out if I didn’t sit down. [You know when someone passes out at a festival and people surround them in a circle and start pointing at them with their arms in the air, then more and more people join in to get medics attention? I dreaded that that would be me.] Luckily we were opposite a medic/ security station. I mustered all the energy I had to jump so the security man could lift me over the metal fence and sit me down with some water. I can only imagine they thought I was another drunk young girl who didn’t know her limits. I didn’t have the energy to explain it was quite the opposite, I was sober and all day I’d only been drinking water. After I’d had some water and space to breathe, away from the crowds, I felt completely fine again (if not a little exhausted from the anxiety attack). "), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "Most recently I had a panic attack in the big tent whilst Craig David played. He was one of the reasons we were at the festival in the first place as my best friend loves him, so I knew I couldn’t expect her to leave with me. Festivals make ‘going out for fresh air’ a hard task. Apart from the fact there’s not an abundance of clear open space, if you split up from your friends you may have a hard time finding them again. I knew I had to go and sit down somewhere because the sweaty crowded tent was not an ideal location to pass out in. I told my friends I was going to walk in a straight line out the tent and sit outside on the grass somewhere. Luckily not too far from the tent there was an opening where I was able to sit by myself. I felt a bit silly as my phone had no signal so I couldn’t call anyone or even scroll through insta. I was also worried the groups of friends around me would be judging me and questioning why I was sat by myself. This being said, sitting on the grass by myself was a MUCH better option than having an anxiety attack in a crowded tent. At the end of his set I felt better and my friends were able to find me so we could move onto our next motive."), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "My ideal festival situation is probably going with my boyfriend. He’s able to stand with his arms around me and firm the battering from the busy crowds so we can bop away in our own bubble (I’m bringing ‘bop’ back). To everyone else it just looks like PDA but for me it reduces my anxiety. He’ll also take me out of the crowds if it gets too much without complaining about it. I also personally think I’d be less anxious if I drank alcohol at festivals but the thought of not having to use the portaloos if I stick to water keeps my sober ass going all day."), "\n", React.createElement(_components.p, null, "If you’ve never been to a festival because your anxiety deters you, you could try going to a cheaper local day festival to first experience it. You could have a friend or parent who isn’t going on standby if you need to leave early and tell the people you’re going with how you feel so they can support you if you need it. Wear something you feel comfortable in because that’s an extra concern you don’t need. "), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "“Music has healing power. It has the ability to take people out of themselves for a few hours.”"), " ", React.createElement(_components.strong, null, "Elton John")), "\n", React.createElement(_components.p, null, "Anxious Extrovert"), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
